import wpAxios from "@/src/util/request";
import { BASE_URL } from "../../const/const";
export async function searchClinic(
  name,
  province_id,
  time,
  page,
  category_service,
  weekday,
  district_id,
  ward_id,
  min,
  max
) {
  console.log("ward_id", ward_id);
  const result = await wpAxios().get(`${BASE_URL}/clinic/search`, {
    params: {
      name: name ? name : null,
      page: 1,
      limit: 10,
      province_id: province_id ? province_id : null,
      time: time ? time : null,
      page: page ? page : 1,
      category_service: category_service ? category_service : null,
      weekday: weekday ? weekday : null,
      district_id: district_id ? district_id : null,
      ward_id: ward_id ? ward_id : null,
      min: min ? min * 1000000 : null,
      max: max ? max * 1000000 : null,
    },
  });
  return result;
}

export async function clinicSearchRecommend(
  longitude,
  latitude,
  province_id,
  district_id,
  listClinicsExcept
) {
  const result = await wpAxios().get(
    `${BASE_URL}/locating/clinicSearchRecommend`,
    {
      params: {
        longitude,
        latitude,
        province_id,
        district_id,
        listClinicsExcept,
      },
    }
  );
  return result;
}

export async function getPromotion(province_id) {
  const result = await wpAxios().get(`${BASE_URL}/promotion/validPromotion`,{
    params:{
      province_id: province_id
    }
  });
  return result;
}

export async function searchClinicByLocation(
  page,
  lon,
  lat,
  category_service,
  weekday,
  min,
  max,
  limit,
  exceptClinicId,
  type
) {
  const result = await wpAxios().get(
    `${BASE_URL}/locating/clinicsNearByLatLon`,
    {
      params: {
        page: page ? page : 1,
        limit: limit ? limit : 10,
        longitude: lon,
        latitude: lat,
        category_service: category_service ? category_service : null,
        weekday: weekday ? weekday : null,
        min: min ? min * 1000000 : null,
        max: max ? max * 1000000 : null,
        exceptClinicId: exceptClinicId,
        type: type,
      },
    }
  );
  return result;
}

export async function getCategoryIntro(name, province_id) {
  const result = await wpAxios().get(`${BASE_URL}/category-service-intro`, {
    params: {
      limit: 20,
      name: name,
      province_id: province_id,
    },
  });
  return result.data.data[0];
}

export async function getLatLonByIp() {
  const result = await wpAxios().get(`${BASE_URL}/locating/getLatLonByIp`);
  return result.data;
}

export async function getMostSearched(params) {
  const result = await wpAxios().get(`${BASE_URL}/clinic/mostSearched`, {
    params: {
      page: params?.page ? params.page : 1,
      limit: params?.limit ? params.limit : 12,
    },
  });
  return result.data;
}
