"use client";
import "./chatbox.scss";
import iconStatus from "@/public/icon/icon-status.png";
import iconChatBox from "@/public/icon/icon-chatbox.png";
import Image from "next/image";
import {
  amountContact,
  createContact,
  createContactAdvice,
  getAllService,
} from "../../app/service";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Col,
  Row,
  message,
} from "antd";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import FormItem from "antd/es/form/FormItem";
import { IMAGE_TYPE, PREFIX_IMAGE_URL } from "@/src/const/const";
import defaultImage from "@/public/image/default-image-square.png";
import { ConfigProvider } from "antd";
import viVN from "antd/locale/vi_VN";
import { filterOption, prefixDoctor } from "../../util/util";
import ImageCommon from "@/src/component/image/image";
import iconLocation from "@/public/icon/icon-location-newest.svg";
import iconStar from "@/public/icon/icon-start-3d.svg";
import iconCheckMark from "@/public/icon/icon-check-mark.png";
import { de } from "suneditor/src/lang";
import { searchClinicByLocation } from "@/src/app/tim-nha-khoa/service";
import { getCoupon } from "../coupon/get-coupon-service";
import Link from "next/link";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import FriendlySelect from "@/src/component/friendly-antd/friendly-select";
import { SERVICE_FOR_SELECT, SERVICE } from "@/src/const/variable";
dayjs.locale("vi");
const durentTime = {
  value: [
    "08:00",
    "08:30",
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
  ],
};
function ChatBox(props) {
  const { detailClinic, isModal = false, doctor } = props;
  const [form] = Form.useForm();
  const chatBodyRef = useRef(null);
  const [data, setData] = useState([]);
  const [appendChatBoxSuccess, setAppendChatBoxSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orderDate, setOrderDate] = useState(dayjs());
  const [orderTime, setOrderTime] = useState(null);
  const [relatedClinicInArea, setRelatedClinicInArea] = useState([]);
  const [btnDisabledIds, setBtnDisabledIds] = useState([]);
  const [codeCoupon, setCodeCoupon] = useState(null);

  const avatarUrl = detailClinic?.image?.find(
    (item) => item.image_type === IMAGE_TYPE.avatar
  );

  let avatar = defaultImage;

  if (doctor) {
    if (doctor?.avatar?.key)
      avatar = `${PREFIX_IMAGE_URL}${doctor?.avatar?.key}`;
  } else avatar = `${PREFIX_IMAGE_URL}${avatarUrl?.key}`;

  const newDurentTime = useMemo(() => {
    if (!orderDate) return null;
    let durentTimeActive = {
      label: "Lịch khám: ",
      value: [],
    };

    if (!orderDate.isSame(dayjs(), "day")) {
      durentTimeActive.value = durentTime.value;
    } else {
      const hourNow = dayjs().add(30, "minutes").format("HH:mm");
      durentTimeActive.value = durentTime.value.filter(
        (subItem) => subItem > hourNow
      );
    }
    return durentTimeActive;
  }, [orderDate]);

  useEffect(() => {
    async function fetchData() {
      setData({
        listService: SERVICE,
        provinceId: parseInt(
          detailClinic?.province_id
            ? detailClinic.province_id[0]
            : detailClinic?.address[0]?.province?.id
        ),
        districtId: parseInt(
          detailClinic?.district_id
            ? detailClinic.district_id[0]
            : detailClinic.address[0]?.district?.id
        ),
      });
    }
    fetchData();
    setInitialChatBodyHeight();
  }, []);

  const handleOrderDate = async (date) => {
    setOrderDate(date);
    const scroll = setTimeout(() => autoScroll(), 100);
    return () => {
      clearTimeout(scroll);
    };
  };

  const onCreateBooking = async (fieldsValue) => {
    const orderDate = fieldsValue.order_date
      ? dayjs(fieldsValue.order_date).format("DD/MM/YYYY")
      : "";
    await createContact({
      ...fieldsValue,
      clinic_id: detailClinic.id,
      province_id: data.provinceId,
      district_id: data.districtId,
      order_date: orderDate,
      order_time: orderTime?.value,
      landing_page_url: localStorage?.getItem("firstVisitPage")
        ? localStorage?.getItem("firstVisitPage")
        : window?.location?.href,
      referrer: localStorage?.getItem("referrer")
        ? localStorage?.getItem("referrer")
        : "",
      pathological_condition: fieldsValue.pathological_condition,
    });
  };

  const autoScroll = (offset = 0, maxRetries = 10) => {
    let attempts = 0;

    const scrollToElement = () => {
      const container = isModal
        ? document.querySelector(".ant-modal .wp-chatBoxBody")
        : document.querySelector(".wp-chatBoxBody");
      const target = document.querySelector(".wp-content-success");

      if (container && target) {
        const targetPosition = target.offsetTop - container.offsetTop;
        requestAnimationFrame(() => {
          container.scrollTo({
            top: targetPosition - container.scrollTop,
            behavior: "smooth",
          });
        });
      } else if (attempts < maxRetries) {
        attempts++;
        requestAnimationFrame(scrollToElement);
      } else {
        console.log("Không tìm thấy phần tử sau", maxRetries, "lần thử.");
      }
    };

    requestAnimationFrame(scrollToElement);
  };

  const setInitialChatBodyHeight = () => {
    const isMobileSmall =
      typeof window !== "undefined" && window.innerWidth <= 375;
    if (!isMobileSmall && chatBodyRef.current) {
      const wpHeight = chatBodyRef.current.scrollHeight;
      chatBodyRef.current.style.height = `${wpHeight}px`;
    }
  };

  const validContract = detailClinic?.user?.user_agreements?.length > 0;
  const onCreateContact = async (clinic_id) => {
    const fieldsValue = await form.validateFields();
    const problemContact = data.listService?.find(
      (item) => item.id === fieldsValue.category_service_id
    )?.name;
    const body = {
      customer_name: fieldsValue.customer_name,
      customer_phone_number: fieldsValue.customer_phone_number,
      note: problemContact,
      clinic_id: clinic_id,
      type: "ADMIN",
      created_at: dayjs(new Date()).format("HH:mm - DD/MM/YYYY"),
      landing_page_url: localStorage?.getItem("firstVisitPage")
        ? localStorage?.getItem("firstVisitPage")
        : window?.location?.href,
      referrer: localStorage?.getItem("referrer")
        ? localStorage?.getItem("referrer")
        : "",
      url: window?.location?.href,
    };
    await createContactAdvice(body);
  };

  const onGetCoupon = async () => {
    try {
      const bodyGetCoupon = {
        clinic_id: detailClinic.id,
        customer_name: fieldsValue.customer_name,
        phone_number: fieldsValue.customer_phone_number,
        landing_page_url: localStorage?.getItem("firstVisitPage")
          ? localStorage?.getItem("firstVisitPage")
          : window?.location?.href,
        referrer: localStorage?.getItem("referrer")
          ? localStorage?.getItem("referrer")
          : "",
        user_agent: navigator?.userAgent,
      };
      const dataCoupon = await getCoupon(bodyGetCoupon);
      setCodeCoupon(dataCoupon?.data?.code);
    } catch (error) {
      console.error("Error fetching coupon:", error);
    }
  };

  const onClinicArea = async (latitude, longitude) => {
    try {
      const dataClinic = await searchClinicByLocation(
        1,
        longitude,
        latitude,
        null,
        null,
        null,
        null,
        3,
        detailClinic.id,
        "CLEAR_PIN"
      );
      setRelatedClinicInArea(dataClinic?.data?.data);
    } catch (error) {
      console.error("Error fetching clinic area:", error);
    }
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      const fieldsValue = await form.validateFields();
      await onCreateBooking(fieldsValue);
      if (validContract) {
        await onGetCoupon();
      }
      const latitude =
        detailClinic?.latitude || detailClinic?.address?.[0]?.latitude;

      const longitude =
        detailClinic?.longitude || detailClinic?.address?.[0]?.longitude;

      if (latitude && longitude) {
        await onClinicArea(latitude, longitude);
      }
      setAppendChatBoxSuccess(true);
      autoScroll();
      setLoading(false);
    } catch (error) {
      console.log("Error during onFinish:", error);
      setLoading(false);
    }
  };

  return (
    <div className="wp-chatBox">
      <div className={"wp-chatBoxHeader"}>
        {/* <div className={"d-flex"}> */}
        {/* <Image
            unoptimized
            src={avatar}
            width={55}
            height={55}
            className="chatBoxAvatar"
            alt="Avatar icon"
          /> */}
        {/* <div> */}
        <div className={"clinicName"}>Đặt lịch giữ chỗ</div>
        {/* <div
            className={"d-flex"}
            style={{
              marginTop: "5px",
            }}
          >
            <Image
                src={iconStatus}
                className={"clinicStatusIcon"}
                alt="Status icon"
              />
              <div className={"clinicStatusText"}>Online</div>
          </div> */}
        {/* </div> */}
        {/* </div> */}
      </div>
      <div className={"wp-chatBoxBody"} ref={chatBodyRef}>
        <div className={"chatContainer"}>
          <div className={"rowChat"}>
            <div className={"chatContent"}>
              Cảm ơn quý khách đã quan tâm đến dịch vụ của chúng tôi. Vui lòng
              nhập các thông tin để đặt lịch hẹn tại nha khoa.
            </div>
            <div className={"chatContent"}>
              <Form
                form={form}
                layout="vertical"
                scrollToFirstError
                data-clarity-unmask="true"
              >
                <FormItem
                  name="customer_name"
                  rules={[
                    {
                      required: true,
                      message: "Bạn chưa nhập Họ tên",
                    },
                    {
                      max: 30,
                      message: "Họ tên không được vượt quá 30 ký tự",
                    },
                  ]}
                >
                  <Input
                    value=""
                    className="chatBoxInput"
                    placeholder="Họ và tên"
                  />
                </FormItem>
                <FormItem
                  name="customer_phone_number"
                  rules={[
                    {
                      required: true,
                      message: "Bạn chưa nhập Số điện thoại",
                    },
                    {
                      pattern: /(03|05|07|08|09)+([0-9]{8})\b/,
                      message: "Số điện thoại không đúng định dạng",
                    },
                  ]}
                >
                  <Input
                    className="chatBoxInput"
                    placeholder="Số điện thoại"
                    inputMode="numeric"
                  />
                </FormItem>
                <FormItem
                  style={{ marginBottom: 8 }}
                  name="category_service_id"
                  rules={[
                    {
                      required: true,
                      message: "Bạn chưa chọn Dịch vụ",
                    },
                  ]}
                >
                  <FriendlySelect
                    options={SERVICE_FOR_SELECT}
                    onChange={(value, option) => {
                      form.setFieldValue("category_service_id", value);
                    }}
                    className="chatBoxSelect"
                    placeholder="Dịch vụ nha khoa"
                  />
                </FormItem>
                <FormItem name="pathological_condition">
                  <Input
                    className="chatBoxInput"
                    placeholder="Tình trạng bệnh lý"
                  />
                </FormItem>
                <ConfigProvider locale={viVN}>
                  <FormItem
                    name="order_date"
                    className="wp-date"
                    label="Ngày thăm khám: "
                    initialValue={orderDate}
                  >
                    <DatePicker
                      className="chatBoxInput"
                      defaultValue={orderDate}
                      value={orderDate}
                      style={{ width: "100%" }}
                      disabled={appendChatBoxSuccess}
                      disabledDate={(current) =>
                        current && current < dayjs().startOf("day")
                      }
                      onChange={(date) => handleOrderDate(date)}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                    />
                  </FormItem>
                </ConfigProvider>
                {newDurentTime?.value && (
                  <FormItem className="wp-times" name="order_time">
                    <div className="wp-time">
                      <Row gutter={[8, 2]}>
                        {newDurentTime?.value?.map((value, index) => {
                          const isActive = orderTime?.index === index;
                          return (
                            <Col xs={6} key={index}>
                              <Button
                                className={`chatBoxInput btn-select-time ${
                                  isActive ? "selectedBtnTime" : ""
                                }`}
                                onClick={() => {
                                  if (appendChatBoxSuccess) {
                                    return;
                                  } else if (isActive) {
                                    setOrderTime(null);
                                    form.setFieldValue("order_time", null);
                                  } else {
                                    setOrderTime({ value, index });
                                    form.setFieldValue("order_time", value);
                                  }
                                }}
                                block
                              >
                                {value}
                              </Button>
                            </Col>
                          );
                        })}
                      </Row>
                    </div>
                  </FormItem>
                )}
              </Form>
            </div>
          </div>
        </div>
        {appendChatBoxSuccess && (
          <div className={"chatContainer"} id="wp-request-successful">
            <div className={"rowChat"}>
              <div className={"chatContent wp-content-success"}>
                <b>Bạn đã đặt lịch thành công!</b>
                <div>
                  Vui lòng lưu ý điện thoại của bạn, nha khoa sẽ liên hệ xác
                  nhận và tư vấn cho bạn trong thời gian sớm nhất.
                </div>
              </div>
              {codeCoupon && (
                <div className={"chatContent wp-coupon"}>
                  <div className="title">
                    Bạn nhận được mã khuyến mãi từ NhaKhoaHub!
                  </div>
                  <div className="wp-code">
                    <span>{codeCoupon}</span>
                  </div>
                  <div className="des">
                    Tìm hiểu thêm về chương trình khuyến mãi tại đây:
                  </div>
                  <Link href={"/blog/khuyen-mai"} className="wp-direct">
                    <div className="direct">
                      Khuyến mãi hoàn tiền Lên tới 1.500.000 VNĐ
                    </div>
                  </Link>
                </div>
              )}
              {relatedClinicInArea?.length > 0 && (
                <>
                  <div className={"chatContent"}>
                    <div>
                      Bạn có thể lựa chọn thêm nha khoa cùng khu vực để nhận tư
                      vấn miễn phí:
                    </div>
                  </div>
                  {relatedClinicInArea.map((item, index) => {
                    const logo = item?.image?.find(
                      (item) => item.image_type === IMAGE_TYPE.avatar
                    );
                    return (
                      <div key={index} className={"relatedClinic"}>
                        <div className={"clinicItem"}>
                          <div className={"clinicContainer"}>
                            <div className={"wpLeft"}>
                              <ImageCommon data={logo} style={"logo"} />

                              {item?.distance > 0 && (
                                <div className={"distance"}>
                                  {(item.distance / 1000)?.toFixed(1)}km
                                </div>
                              )}
                            </div>
                            <div className={"wpRight"}>
                              <div className={"titleClinic"}>{item?.name}</div>
                              {item?.address?.length > 0 && (
                                <div className={"firstLineTag"}>
                                  <div className={"wpTagInfo"}>
                                    <Image
                                      src={iconLocation}
                                      className={"iconLocation"}
                                      alt="Location icon"
                                    />
                                    {item.address[0]}
                                  </div>
                                </div>
                              )}
                              <div className={"wpRatingLine"}>
                                {item?.rating_overview?.overall_average ? (
                                  <>
                                    <div className={"wpRatingInLine"}>
                                      <div className={"ratingPoint"}>
                                        <div className={"point"}>
                                          {item?.rating_overview?.overall_average.toFixed(
                                            1
                                          )}
                                        </div>
                                        <Image
                                          className="iconRatingStar"
                                          src={iconStar}
                                          alt="icon"
                                          width={12}
                                          height={12}
                                        />
                                      </div>
                                    </div>
                                    <div className={"dash"}></div>
                                  </>
                                ) : null}
                                <div className={"ratingQuantity"}>
                                  {item?.rating_overview?.number_of_ratings}{" "}
                                  Đánh giá
                                </div>
                                <div className={"dash"}></div>
                                <div className={"numberOfConsultation"}>
                                  {item?.total_contact
                                    ? item?.total_contact
                                    : 0}{" "}
                                  Người đặt lịch
                                </div>
                              </div>
                            </div>
                          </div>
                          {btnDisabledIds.includes(item.id) ? (
                            <Image
                              src={iconCheckMark}
                              alt="iconCheckMark"
                              width={30}
                              height={30}
                              loading="eager"
                              style={{
                                margin: "8px auto -4px",
                              }}
                            />
                          ) : (
                            <Button
                              className="btn-receive-advice"
                              onClick={() => {
                                onCreateContact(item.id);
                                setBtnDisabledIds((prev) => [...prev, item.id]);
                              }}
                              aria-label="confirm"
                              // disabled={btnDisabledIds.includes(item.id)}
                            >
                              Đồng ý
                            </Button>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        )}
      </div>
      <div className={"wp-chatBoxFooter"}>
        {!appendChatBoxSuccess && (
          <Button
            className={"btnChatBox"}
            onClick={onFinish}
            loading={loading}
            disabled={appendChatBoxSuccess}
          >
            Đặt lịch ngay
          </Button>
        )}
        {appendChatBoxSuccess && (
          <Button className={"btnChatBoxSubmit"} type="primary">
            <Image src={iconChatBox} alt="Chat box icon" />
          </Button>
        )}
      </div>
    </div>
  );
}
export default memo(ChatBox);
