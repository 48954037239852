"use client";
import { Input, Drawer, List, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useState, useCallback, useEffect } from "react";
import { strVNForSearch } from "@/src/util/util";
import _ from "lodash";
import useWindowSize from "@/src/hooks/useWindowSize";
import "./friendly-select.scss";

export default function FriendlySelect({
  options,
  className,
  placeholder,
  onChange = () => {}, // pass when have special onChange
  onClear = () => {},
  initialValue,
  dropdownAlign,
}) {
  const filterOption = (input, option) => {
    if (option.props.label) {
      return strVNForSearch(option.props.label).includes(strVNForSearch(input));
    } else {
      return false;
    }
  };
  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const debounceSearch = _.debounce((searchValue) => {
    setFilteredOptions(
      options.filter((option) =>
        strVNForSearch(option.label).includes(strVNForSearch(searchValue))
      )
    );
  }, 300);
  const showFriendlySelect = useWindowSize().width <= 480;
  const [open, setOpen] = useState(false);

  const closeDrawer = useCallback(() => {
    setOpen(false);
    setFilteredOptions(options);
  }, [setOpen, setFilteredOptions, options]);

  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue, options]);

  return (
    <>
      <Select
        className={className}
        filterOption={filterOption}
        placeholder={placeholder}
        allowClear
        onClear={() => {
          setValue(null);
          onClear();
        }}
        notFoundContent={
          placeholder == "Chọn quận huyện"
            ? "Vui lòng chọn tỉnh thành trước"
            : undefined
        } //just district could has empty data
        showSearch={!showFriendlySelect}
        open={showFriendlySelect ? false : undefined}
        onClick={() => {
          if (showFriendlySelect) {
            setOpen(true);
          }
        }}
        dropdownStyle={{ width: "100%" }}
        dropdownAlign={dropdownAlign}
        value={value}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        onChange={onChange}
        options={options}
        onSelect={(value, option) => {
          setValue(value);
        }}
        labelRender={(option) => {
          const label = option.label;
          if (label?.includes("Thành phố")) {
            return label.replace("Thành phố ", "TP ");
          }
          return label;
        }}
      />

      {showFriendlySelect && (
        <Drawer
          open={open}
          onClose={closeDrawer}
          destroyOnClose
          title="Tìm kiếm"
          placement="right"
          className="friendly-select"
        >
          <Input
            allowClear
            onChange={(e) => debounceSearch(e.target.value)}
            placeholder="Tìm kiếm"
            prefix={<SearchOutlined />}
          />
          <List
            dataSource={filteredOptions}
            locale={{ emptyText: "Vui lòng chọn tỉnh thành trước" }}
            renderItem={(item) => (
              <List.Item
                onClick={() => {
                  // onChange with value and option same as Select
                  onChange(item.value, item);
                  setValue(item.value);
                  closeDrawer();
                }}
              >
                {item.label}
              </List.Item>
            )}
          />
        </Drawer>
      )}
    </>
  );
}
